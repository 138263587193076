<template>
    <div class="bodys">
        <span>
            提示,本页面只获取用户微信openid标识,以便后台查询立减优惠活动使用情况
        </span>
    </div>
</template>

<script>
import { useRoute,} from "vue-router";
import { accesstoken_api,opid_api } from "../store/apis";
export default {
    setup() {
      document.title = "openid获取"
      const route = useRoute();

      if (route.query.code) {
        accesstoken_api(route.query).then((resp) => {
          if (!resp.errcode) {
          alert(resp.openid)
                opid_api({openid:resp.openid}) 
          }
        });
      }else{
         const host = `${document.location.protocol}//${window.location.host}/test`;
         window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc26d1e2d331c6228&redirect_uri=${host}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
    }
       
    },
}
</script>

<style>
.bodys{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    font-size:12px;
}
</style>